<template>
  <div>
    <v-divider class="mb-4"></v-divider>

    <v-row class="mb-0" dense>
      <v-col 
        v-for="(heading, index) in headers"
        :key="`heading-${index}`"
        :cols="heading.width"
      >
        {{ heading.text }}
      </v-col>
    </v-row>

    <order-item 
      v-for="(item, index) in orderItems"
      :key="`order-item-${index}`"
      :item-index="index"
      :item="item"
      class="mt-0"
    />

    <v-btn
      small
      depressed
      class="ttn caption"
      @click="addOrderItem()"
    >
      Add item
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    'order-item': () => import('./OrderItem.vue'),
  },

  data () {
    return {
      headers: [
        { text: 'Product', width: 8, },
        { text: 'Quantity', width: 3, },
        // { text: 'Amount', width: 2, },
        { text: '', width: 1, },
      ],
    }
  },

  computed: {
    ...mapGetters('retail', {
      orderItems: 'getOrderItems'
    }),

    hasEmptyItems () {
      if (this.orderItems.length === 0) {
        return false
      }

      const emptyItem = this.orderItems.find((item) => {
        return !item.price_uid || !Boolean(item.quantity)
      })

      return Boolean(emptyItem)
    }
  },

  methods: {
    ...mapActions('retail', [
      'setOrderItems'
    ]),

    addOrderItem () {
      if (this.hasEmptyItems) {
        flash({
          message: 'Product and quantity are required'
        })
        return
      }

      let orderItems = this.orderItems
      orderItems.push({
        price_uid: null,
        quantity: null,
      })

      this.setOrderItems(orderItems)
    },
  },

  mounted () {
    this.addOrderItem()
  }
}
</script>